<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PButton } from 'primevue/button'
import draggable from 'vuedraggable'

// Custom components and types
// VUE, VUE ROUTER, ETC...
import { computed, onMounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { ISequencingAnswer } from '@/assets/ts/types/learning/answers'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'

const emit = defineEmits(['answer', 'next'])

const props = defineProps<{
  answersContent: ISequencingAnswer[]
  showCorrectAnswers: boolean
}>()

const answers = ref(props.answersContent)
const clicked = ref(false)

onMounted(() => {
  // shuffle a copy of answers
  answers.value = [...answers.value].sort(() => Math.random() - 0.5)
})

const dragOptions = {
  animation: 150,
  group: 'group1',
  disabled: false,
  ghostClass: 'ghost'
}

function updateAnswers() {
  answers.value.forEach((answer, i) => {
    answer.order = i + 1
  })
}

const isCurrentOrderCorrect = computed(() => {
  return JSON.stringify(answers.value) === JSON.stringify(props.answersContent)
})

const isButtonDisabled = computed(() => {
  if (props.showCorrectAnswers) return false

  return clicked.value
})

const handleAnswer = () => {
  if (clicked.value) return

  clicked.value = true
  if (props.showCorrectAnswers) {
    emit('next')
    return
  }
  let userAnswer = {} as IUserAnswer

  userAnswer.answers = answers.value.map((answer) => answer.data.value)
  userAnswer.isCorrect = isCurrentOrderCorrect.value

  emit('answer', userAnswer)
  clicked.value = false
}
</script>

<template>
  <div class="answers">
    <draggable
      v-model="answers"
      v-bind="dragOptions"
      item-key="order"
      :component-data="{ tag: 'div', name: 'flip-list', type: 'transition' }"
      class="list-group grid w-full grid-cols-1 gap-4 auto-rows-[minmax(min-content,1fr)]"
      @end="updateAnswers"
    >
      <template #item="{ element, index }: { element: ISequencingAnswer; index: number }">
        <div class="flex items-center gap-4">
          <i class="bg-white rounded px-2 py-1 text-primary">{{ index + 1 }}</i>
          <div
            class="w-full h-full flex items-center gap-3 border-solid border rounded border-b-3 px-3 py-2 cursor-move"
            :class="[
              { 'border-white': !showCorrectAnswers },
              {
                'border-green-500':
                  showCorrectAnswers &&
                  element.data.value === props.answersContent[index].data.value
              },
              {
                'border-red-500':
                  showCorrectAnswers &&
                  element.data.value !== props.answersContent[index].data.value
              }
            ]"
          >
            <FontAwesomeIcon icon="grip-vertical" class="handle" />

            <p>{{ element.data.value }}</p>
            <i
              v-if="showCorrectAnswers"
              class="pi ml-auto"
              :class="{
                'pi-check text-green-500':
                  element.data.value === props.answersContent[index].data.value,
                'pi-times text-red-500':
                  element.data.value !== props.answersContent[index].data.value
              }"
            />
          </div>
        </div>
      </template>
    </draggable>
  </div>

  <div class="fixed bottom-0 w-full flex justify-center p-8" :class="$style.QuizFooter">
    <p-button
      label="Valider"
      severity="light"
      rounded
      class="mt-auto"
      @click="handleAnswer"
      :disabled="isButtonDisabled"
    />
  </div>
</template>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>

<style module>
.QuizFooter {
  background: linear-gradient(180deg, transparent 0%, var(--surface-blue-500) 40%);
}
</style>
