<script setup lang="ts">
import MStarScore from '@/components/modules/grades/MStarScore.vue'
import EIcon from '@/components/elements/icons/EIcon.vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import type { Task } from '@/assets/ts/classes/progress/task'
import type { Quiz } from '@/assets/ts/classes/progress/quiz'
import { computed, onMounted, ref, useCssModule } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useProfileStore } from '@/stores/profile'
import type { Mission } from '@/assets/ts/classes/progress/mission'
import MascotQuizPostEnd from '@/assets/images/mascottes/quiz/MASCOTTE_Quiz_Post_End.svg'
import MascotQuizPostEndSuccess from '@/assets/images/mascottes/quiz/MASCOTTE_Quiz_Post_End_Success.svg'
import { default as PButton } from 'primevue/button'
import { default as PTag } from 'primevue/tag'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import { storeToRefs } from 'pinia'
import { useProgramStore } from '@/stores/program'
import MStarGrades from '@/components/modules/grades/MStarGrades.vue'

const route = useRoute()
const router = useRouter()
const style = useCssModule()
const emit = defineEmits(['next-task-quiz', 'restart-quiz'])

const showMissionScore = ref(false)
const showMissionEnd = ref(false)

const props = defineProps<{
  task: Task
  quiz: Quiz
  mission: Mission
}>()

const { firstName } = storeToRefs(useProfileStore())

onMounted(async () => {
  await getProgram()
})

const getContents = computed(() => {
  const contents = {} as {
    title: string
    dialog: string
    dialogClass?: string
    primaryAction: {
      label: string
      action: () => void | Promise<void>
    }
    secondaryAction?: {
      label: string
      action: () => void | Promise<void>
    }
    image: string
    imageClass?: string
  }

  if (Number(props.quiz.scaled) <= 0.4) {
    contents.title = 'Fin de tâche'
    contents.dialog =
      'Cette tâche a été difficile et si <span class="font-bold">tu revoyais les contenus </span> une nouvelle fois ?'
    contents.primaryAction = {
      label: 'Revoir les contenus',
      action: () => {
        goToContents()
      }
    }
    contents.secondaryAction = undefined
    contents.image = MascotQuizPostEnd
    contents.imageClass = style.ImageFail
  }

  if (Number(props.quiz.scaled) > 0.4) {
    contents.title = 'Tâche acccomplie !'
    contents.dialog = `Bravo ${firstName.value} ! Sais-tu que tu peux <span class='font-bold'>refaire ce quiz</span> pour augmenter ton score ?`
    contents.primaryAction = {
      label: 'Refaire le quiz',
      action: () => {
        restartQuiz()
      }
    }
    contents.secondaryAction = {
      label: props.mission.isTaskLast(props.task.grainId)
        ? 'Terminer la mission'
        : 'Commencer la tâche suivante',
      action: props.mission.isTaskLast(props.task.grainId)
        ? async () => completeExplorationMission()
        : () => goToNextTask()
    }
    contents.image = MascotQuizPostEndSuccess
    contents.imageClass = style.ImageSuccess
  }

  if (Number(props.quiz.scaled) === 1) {
    contents.title = 'Tâche accomplie !'
    contents.dialog = `Bravo ${firstName.value} ! Tu as obtenu </br> un <span class='font-bold'>score parfait</span> !`
    contents.primaryAction = {
      label: props.mission.isTaskLast(props.task.grainId)
        ? 'Terminer la mission'
        : 'Commencer la tâche suivante',
      action: props.mission.isTaskLast(props.task.grainId)
        ? async () => completeExplorationMission()
        : () => goToNextTask()
    }
  }

  return contents
})

const { getExplorationNameFromMissionId, completeMission, getProgram } = useProgramStore()

const completeExplorationMission = async () => {
  await completeMission(Number(route.params.courseId), props.mission)

  props.mission.completeMission()
  showMissionEnd.value = true
  setTimeout(() => {
    showMissionScore.value = true
  }, 500)
}

const goToNextTask = () => {
  const nextTask = props.mission.getNextTaskByTaskId(props.task.grainId)

  if (nextTask) {
    if (!nextTask.preQuizCompleted) emit('next-task-quiz', nextTask)
    else
      router.push({
        name: 'task',
        params: {
          courseId: route.params.courseId,
          moduleId: route.params.moduleId,
          grainId: nextTask.grainId
        }
      })
  }
}

const goToContents = () => {
  router.push({
    name: 'task',
    params: {
      courseId: route.params.courseId,
      moduleId: route.params.moduleId,
      grainId: props.task.grainId
    }
  })
}

const restartQuiz = () => {
  emit('restart-quiz')
}
</script>

<template>
  <e-icons :load="['Crown', 'Check']" />
  <template v-if="!showMissionEnd">
    <e-progress-bar
      :max="1"
      :value="1"
      :colors="{ progress: 'var(--variant-pink)' }"
      :rounded="false"
    />
    <div class="flex flex-col items-center p-4 pt-12 bg-blue-700 flex-grow gap-8 relative">
      <div class="bg-blue-800 flex flex-col items-center gap-2 p-4 rounded relative">
        <div
          class="absolute top-0 p-2 w-10 h-10 rounded-full bg-blue-800 border-2 border-solid border-blue-700"
          style="transform: translateY(-60%)"
        >
          <e-icon style="--fill: var(--p-pink-500)" name="Crown" type="svg" />
        </div>
        <h3 class="brand-heading medium">Ton score</h3>
        <m-star-score large :points="Math.round(Number(quiz.scaled) * 5)" />
      </div>

      <h3 class="heading large" v-html="getContents.title" />

      <div
        class="bg-white rounded shadow-lg p-3 relative text-primary"
        :class="[$style['VQuiz-Task-Outro']]"
      >
        <p class="text-primary mb-3" v-html="getContents.dialog" />

        <p-button
          rounded
          :label="getContents.primaryAction.label"
          severity="primary"
          class="text-white"
          @click="getContents.primaryAction.action"
        />
      </div>

      <img
        :src="getContents.image"
        alt="Mascotte"
        class="max-w-72 absolute left-0 bottom-0"
        :class="getContents.imageClass"
      />

      <div class="flex flex-col gap-2 items-center absolute bottom-0 py-8">
        <p-button
          v-if="getContents.secondaryAction"
          rounded
          :label="getContents.secondaryAction.label"
          severity="light"
          @click="getContents.secondaryAction?.action"
        />

        <a @click="$router.push({ name: 'logbook' })" class="text-white underline"
          >Retourner au tableau de bord</a
        >
      </div>
    </div>
  </template>

  <div v-else class="flex flex-col items-center gap-4 justify-center flex-grow">
    <div class="flex flex-col items-center gap-4 justify-center transition">
      <div class="w-12 h-12 rounded-full border-3 border-solid border-success p-2">
        <e-icon name="Check" type="svg" style="--fill: var(--context-success)" />
      </div>
      <h2 class="heading large">Mission terminée</h2>
      <p>
        Félicitations <span class="font-bold">{{ firstName }}</span
        >, tu as complété ta mission !
      </p>
    </div>

    <transition name="slide-from-bottom">
      <div
        v-if="showMissionScore"
        class="bg-blue-800 flex flex-col items-center gap-2 p-8 rounded relative"
      >
        <p-tag
          class="uppercase font-secondary text medium text-orange-900 bg-orange-300 px-2 py-0"
          rounded
        >
          Mission {{ getExplorationNameFromMissionId(mission.moduleId) }}-{{
            mission.order < 10 ? '0' : ''
          }}{{ mission.order }}
        </p-tag>
        <h3 class="brand-heading medium">{{ mission.title }}</h3>
        <m-star-grades size="large" :points="mission.score.score" />
      </div>
    </transition>

    <div class="flex flex-col gap-2 items-center absolute bottom-0 py-8">
      <p-button
        rounded
        label="Voir la liste des explorations"
        severity="light"
        @click="() => $router.push({ name: 'program' })"
      />

      <a @click="$router.push({ name: 'logbook' })" class="text-white underline"
        >Retourner au tableau de bord</a
      >
    </div>
  </div>
</template>

<style module>
.VQuiz-Task-Outro {
  max-width: 20rem;
  text-align: center;
  position: relative;
  transition: all 0.5s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 10%;
    transform: translateX(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid white;
  }
}

.ImageFail {
  transform: translatex(-20%);
}

.ImageSuccess {
  transform: translatex(-30%);
}
</style>

<style scoped>
.slide-from-bottom-enter-active,
.slide-from-bottom-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-from-bottom-enter-from,
.slide-from-bottom-leave-to {
  transform: translateY(100%);
}
</style>
