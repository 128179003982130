<script setup lang="ts">
import { default as PRadioButton } from 'primevue/radiobutton'
import { default as PButton } from 'primevue/button'
import { computed, type Ref, ref } from 'vue'
import type { ITrueFalseAnswer } from '@/assets/ts/types/learning/answers'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'

const emit = defineEmits(['answer', 'next'])
const clicked = ref(false)
const props = defineProps<{
  answersContent: ITrueFalseAnswer[]
  showCorrectAnswers: boolean
}>()

const selectedUserAnswer: Ref<string> = ref('')

const getCorrectAnswer = computed(() => {
  return props.answersContent.filter((answer) => answer.isCorrect)[0].label
})

const isAnswerSelectedCorrect = computed(() => {
  return selectedUserAnswer.value === getCorrectAnswer.value
})

const isButtonDisabled = computed(() => {
  if (props.showCorrectAnswers) return false

  return selectedUserAnswer.value.length === 0 || clicked.value
})

const handleAnswer = () => {
  if (clicked.value) return

  clicked.value = true
  if (props.showCorrectAnswers) {
    emit('next')
    return
  }
  let userAnswer = {} as IUserAnswer

  userAnswer.answers = []
  userAnswer.isCorrect = isAnswerSelectedCorrect.value

  emit('answer', userAnswer)

  clicked.value = false
}
</script>

<template>
  <form class="grid grid-cols-1 gap-4 w-full auto-rows-[minmax(min-content,1fr)] answers">
    <div v-for="(answer, i) in answersContent" class="flex items-center gap-2" :key="'answer_' + i">
      <p-radio-button
        style="display: none"
        :value="answer.label"
        :inputId="answer.label"
        v-model="selectedUserAnswer"
        :disabled="showCorrectAnswers || clicked"
      />
      <label
        class="text bg-surface-blue rounded-lg p-4 flex-grow h-full flex items-center justify-between min-h-24"
        :for="answer.label"
        :class="{
          'border-2 border-solid': showCorrectAnswers && answer.label === selectedUserAnswer,
          'border-green-500': showCorrectAnswers && answer.isCorrect,
          'border-red-500': answer.isCorrect !== (answer.label === selectedUserAnswer),
          'opacity-50': showCorrectAnswers && answer.label !== selectedUserAnswer
        }"
        >{{ answer.label }}
        <i
          v-if="showCorrectAnswers && (answer.isCorrect || answer.label === selectedUserAnswer)"
          class="pi font-bold text-xl"
          :class="{
            'pi-check text-green-500': answer.isCorrect && answer.label === selectedUserAnswer,
            'pi-times text-red-500': !answer.isCorrect && answer.label === selectedUserAnswer
          }"
        />
      </label>
    </div>
  </form>

  <div class="fixed bottom-0 w-full flex justify-center p-8" :class="$style.QuizFooter">
    <p-button
      :label="showCorrectAnswers ? 'Question suivante' : 'Valider'"
      severity="light"
      rounded
      class="mt-auto"
      @click="handleAnswer"
      :outlined="isButtonDisabled"
      :disabled="isButtonDisabled"
    />
  </div>
</template>

<style module>
.QuizFooter {
  background: linear-gradient(180deg, transparent 0%, var(--surface-blue-500) 40%);
}
</style>
