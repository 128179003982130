<script lang="ts" setup>
// PRIMEVUE COMPONENTS
import { default as PButton } from 'primevue/button'
// CUSTOM COMPONENTS
import EIcon from '@/components/elements/icons/EIcon.vue'
import SupportBaseBlock from '@/views/training/blocks/SupportBaseBlock.vue'
// VUE, VUE ROUTER, ETC...
import { computed } from 'vue'
// IMAGES
import GeniallyMin from '@/assets/images/Miniature_Genially.png'
import type { Support } from '@/assets/ts/classes/progress/support'

// EMITS
const emits = defineEmits(['start-support', 'show-support-drawer'])

// PROPS
const props = defineProps<{
  support: Support
  index: number
  disabled: boolean
  isCurrent: boolean
}>()

// DATA & REFS

// COMPUTED
const state = computed(() => {
  if (props.disabled) return 'locked'

  if (props.isCurrent) return 'current'

  if (props.support.isCompleted) return 'completed'

  return 'locked'
})

//METHODS
const startSupport = () => {
  if (props.disabled) return

  emits('start-support')
}
</script>

<template>
  <support-base-block type="support" :state="state">
    <div
      class="flex items-center gap-2 w-full"
      @click="startSupport"
      :class="{ 'cursor-pointer': !disabled }"
    >
      <img :src="GeniallyMin" alt="Genially" />

      <p>Support n°{{ index + 1 }}</p>

      <p-button
        class="ml-auto w-12 h-12"
        :severity="disabled ? 'primary' : 'light'"
        :outlined="disabled"
        rounded
        :disabled="disabled"
      >
        <div class="w-6">
          <e-icon name="Play" type="svg" style="--fill: var(--primary-lavender)" />
        </div>
      </p-button>
    </div>
  </support-base-block>
  <div
    v-if="support.isStarted && !support.isCompleted"
    class="flex bg-white p-3 rounded-2xl items-center gap-2 relative mt-3"
    :class="$style.SupportAlert"
  >
    <p class="text-4xl">⚠️</p>
    <p class="text-primary">Tu dois terminer cette caspule avant de passer à la suivante.</p>
  </div>
</template>

<style module lang="scss">
.Grain-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  aspect-ratio: 16/9;
  transition: all 0.3s ease;

  .Grain-Body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: url('@/assets/images/backgroundAssets/sessions/Dark_2.svg');
    background-size: cover;

    .Button-Show-Support {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
  }
}

.SupportAlert {
  &::before {
    content: '';
    position: absolute;
    bottom: calc(100% - 1px);
    left: 20%;
    transform: translateX(50%);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid white;
  }
}
</style>
