<script setup lang="ts">
import { default as PButton } from 'primevue/button'
import { computed, type Ref, ref } from 'vue'
import type { IAnswerValue, IMatchingAnswer } from '@/assets/ts/types/learning/answers'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'

const emit = defineEmits(['answer', 'next'])

// TYPES
interface Label extends IAnswerValue {
  isPlaced: boolean
}

interface Target extends IAnswerValue {
  matchedLabel: IAnswerValue | null
  expectedLabel: string
}

// PROPS & REFS
const props = defineProps<{
  answersContent: IMatchingAnswer[]
  showCorrectAnswers: boolean
}>()

const selectedLabel: Ref<Label | null> = ref(null)
const clicked = ref(false)

const labels: Ref<Label[]> = ref(
  props.answersContent.map((answer) => {
    return { ...answer.left, isPlaced: false }
  })
)
const targets: Ref<Target[]> = ref(
  props.answersContent
    .map((answer) => ({
      ...answer.right,
      matchedLabel: null as IAnswerValue | null,
      expectedLabel: answer.left.value
    }))
    .sort(() => Math.random() - 0.5)
)

// COMPUTED
const getAnswersSelected = computed(() => {
  return targets.value.map((target) => `${target.value}[.]${target.matchedLabel?.value}`)
})

const expectedAnswers = computed(() => {
  let answers: Record<string, string> = {}
  props.answersContent.forEach((answer) => {
    answers[`${answer.right.value}`] = answer.left.value
  })
  return answers
})

const getUserAnswers = computed(() => {
  let answers: Record<string, string> = {}
  targets.value.forEach((answer) => {
    answers[`${answer.value}`] = answer.matchedLabel?.value || ''
  })
  return answers
})

const allLabelsPlaced = computed(() => {
  return labels.value.every((label) => label.isPlaced)
})

const isButtonDisabled = computed(() => {
  if (props.showCorrectAnswers) return false

  return !allLabelsPlaced.value || clicked.value
})

// METHODS
function onDragStart(label: Label) {
  if (props.showCorrectAnswers || clicked.value) return

  selectedLabel.value = label
}

function onDrop(target: Target) {
  if (selectedLabel.value) {
    target.matchedLabel = selectedLabel.value
    selectedLabel.value.isPlaced = true
    selectedLabel.value = null
  }
}

function onLabelClick(label: Label) {
  if (props.showCorrectAnswers || clicked.value) return
  if (selectedLabel.value === label) {
    selectedLabel.value = null
  } else {
    label.isPlaced ? (selectedLabel.value = null) : (selectedLabel.value = label)
  }
}

function onTargetClick(target: Target) {
  if (props.showCorrectAnswers || clicked.value) return
  if (selectedLabel.value) {
    if (target.matchedLabel) {
      let label = labels.value.find((label) => label.value === target.matchedLabel?.value)

      if (label) {
        label.isPlaced = false
      }
    }
    target.matchedLabel = selectedLabel.value
    selectedLabel.value.isPlaced = true
    selectedLabel.value = null
    return
  }

  if (target.matchedLabel) {
    selectedLabel.value =
      labels.value.find((label) => label.value === target.matchedLabel?.value) || null

    if (selectedLabel.value) selectedLabel.value.isPlaced = false

    target.matchedLabel = null
    return
  }
}

const handleAnswer = () => {
  if (clicked.value) return

  clicked.value = true
  if (props.showCorrectAnswers) {
    emit('next')
    return
  }

  let userAnswer = {} as IUserAnswer

  userAnswer.answers = getAnswersSelected.value

  userAnswer.isCorrect = Object.keys(getUserAnswers.value).every((key) => {
    return getUserAnswers.value[key] === expectedAnswers.value[key]
  })

  emit('answer', userAnswer)
  clicked.value = false
}
</script>

<template>
  <div class="answers">
    <p class="font-bold text-center">Place les étiquettes au bon endroit.</p>

    <div class="labels flex flex-wrap gap-4 justify-center mt-4">
      <div
        v-for="(label, index) in labels"
        :key="index"
        class="px-3 py-2 border-white border-solid border rounded label border-b-3 min-w-24"
        :class="[
          { 'bg-primary-500 text-white border-none': label.isPlaced },
          { 'border-purple-500 bg-white text-primary': selectedLabel === label }
        ]"
        draggable="true"
        @dragstart="onDragStart(label)"
        @click="onLabelClick(label)"
      >
        <template v-if="!label.isPlaced">
          {{ label.value }}
        </template>
        <template v-else> &nbsp;</template>
      </div>
    </div>

    <div class="mt-5 grid grid-cols-1 gap-4">
      <div
        v-for="target in targets"
        :key="target.value"
        class="target"
        @dragover.prevent
        @drop="onDrop(target)"
        @click="onTargetClick(target)"
      >
        <div class="flex flex-col items-center">
          <span class="text-center">{{ target.value }}</span>
          <div
            class="px-3 py-2 border-solid border rounded label border-b-3 min-w-24 mt-3 justify-between flex"
            :class="[
              { 'border-white': !showCorrectAnswers },
              { 'bg-white text-primary': target.matchedLabel },
              { 'border-purple-500': target.matchedLabel && !showCorrectAnswers },
              { 'bg-primary-500 text-primary': !target.matchedLabel },
              {
                'border-green-500':
                  showCorrectAnswers && target.matchedLabel?.value === target.expectedLabel
              },
              {
                'border-red-500':
                  showCorrectAnswers && target.matchedLabel?.value !== target.expectedLabel
              }
            ]"
          >
            <template v-if="target.matchedLabel">
              {{ target.matchedLabel.value }}
            </template>
            <template v-else> &nbsp;</template>

            <i
              v-if="showCorrectAnswers"
              class="pi font-bold text-xl"
              :class="{
                'pi-check text-green-500':
                  target.matchedLabel && target.matchedLabel.value === target.expectedLabel,
                'pi-times text-red-500':
                  target.matchedLabel && target.matchedLabel.value !== target.expectedLabel
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fixed bottom-0 w-full flex justify-center p-8" :class="$style.QuizFooter">
    <p-button
      :label="showCorrectAnswers ? 'Question suivante' : 'Valider'"
      severity="light"
      rounded
      class="mt-auto"
      @click="handleAnswer"
      :outlined="!allLabelsPlaced"
      :disabled="isButtonDisabled"
    />
  </div>
</template>

<style module>
.QuizFooter {
  background: linear-gradient(180deg, transparent 0%, var(--surface-blue-500) 40%);
}
</style>
